.company-container {
    display: flex;
}

.company-container .image-container {
    display: flex;
    width: 300px;
    height: 300px;
    padding: 0 40px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.company-container .image-container img {
    padding: 15px;
    width: 100%;
    object-fit: contain;
    border-radius: 15px;
}

.dark-image {  
    background: rgba(0,0,0,0.5);
}

.light-image {  
    background: rgba(255,255,255,0.5);
}

.company-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
}

.company-details h1 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.company-details p {
    margin-bottom: 2rem;
}

.company-details button {
    font-size: 1.25rem;
    width: 200px;
}

@media (max-width: 800px) {
    .company-container {
      flex-direction: column;
      align-items: center;
    }

    .company-container .image-container {
        padding: 0;
        width: 200px;
        height: 200px;
    }

    .company-details {
        align-items: center;
    }
  }