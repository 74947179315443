.client-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.client-container .image-container {
    display: flex;
    flex-grow: 1;
    width: 250px;
    height: 120px;
    margin-bottom: 20px;
    box-shadow: 0 0 2px 2px #f8f8f8;
    border-radius: 10px;
}

.client-container .image-container img {
    padding: 20px;
    width: 100%;
    object-fit: contain;
    border-radius: 10px;
}

.dark-image {  
    background: rgba(0,0,0,0.1);
}

.light-image {  
    background: rgba(255,255,255,0.2);
}

.project-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 5px;
    width: 85%;
    margin-bottom: 20px;
}

.project-links button {
    font-size: 1.25rem;
}

@media (max-width: 991px) {
    .client-container {
      flex-direction: column;
      align-items: center;
      margin: 0;
    }

    .client-container .image-container {
        padding: 0;
        width: 200px;
        height: 200px;
    }
}

@media (max-width: 500px) {
    .client-container {
      width: 100%;
    }
}