.image-gallery img {
    width: 100%; 
    aspect-ratio: 1/1;
    object-fit: cover;
    transition: scale 350ms ease, opacity 350ms linear;
}

.image-gallery:has(img:hover) img:not(:hover) {
    scale: 0.8;
    opacity: 0.7;
}

.image-gallery-rank {
    font-size: 2rem;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 2px 2px #ff0000;
}