.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.footer.dark {
    background-color: rgb(33,37,41);
    color: white;
}

/* .footer-dark {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgb(33,37,41);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
} */