:root {
  --red: #EA5E5E;
  --yellow: #F7BA3E;
  --blue: #56B3B4;
  --purple: #BF85BF;
  --background: #f8f8f8;
  --gray: transparent;
  --unit: 15px;
  --dark: #3B3734;
}

.animation-container {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  height: 90vh;
  justify-content: center;
  max-width: 1000px;
  animation: disappear 2s 3000ms forwards;
  translate: 0;
  margin: auto;
}

.animation-row {
  height: var(--unit);
  display: flex;
  gap: var(--unit);
  translate: var(--offset);
}

.animation-row {
  animation: load-row 1s calc(var(--delay) * 100ms + 500ms) forwards;
}

.cell {
  height: 100%;
  width: calc(var(--size, 2) * var(--unit));
  background-color: var(--gray);
  border-radius: 5px;
}

.cell.size-2 { --size: 2; }
.cell.size-3 { --size: 3; }
.cell.size-4 { --size: 4; }
.cell.size-5 { --size: 5; }
.cell.size-6 { --size: 6; }
.cell.size-7 { --size: 7; }
.cell.size-8 { --size: 8; }
.cell.size-9 { --size: 9; }
.cell.size-10 { --size: 10; }
.cell.size-12 { --size: 12; }
.cell.size-14 { --size: 14; }
.cell.size-16 { --size: 16; }
.cell.size-18 { --size: 18; }
.cell.size-20 { --size: 20; }
.cell.size-22 { --size: 22; }
.cell.size-24 { --size: 24; }
.cell.size-26 { --size: 26; }
.cell.size-28 { --size: 28; }
.cell.size-30 { --size: 30; }
.cell.size-32 { --size: 32; }
.cell.size-34 { --size: 34; }
.cell.size-36 { --size: 36; }
.cell.size-38 { --size: 38; }
.cell.size-40 { --size: 40; }

.cell.blue { background-color: var(--blue); }
.cell.red { background-color: var(--red); }
.cell.yellow { background-color: var(--yellow); }
.cell.purple { background-color: var(--purple); }
.cell.dark { background-color: var(--dark); }

.animation-row:nth-child(1) { --delay: 1; --offset: 150px }
.animation-row:nth-child(2) { --delay: 2; --offset: -100px }
.animation-row:nth-child(3) { --delay: 3; --offset: 200px }
.animation-row:nth-child(4) { --delay: 4; --offset: -100px }
.animation-row:nth-child(5) { --delay: 5; --offset: 200px }
.animation-row:nth-child(6) { --delay: 6; --offset: -50px }
.animation-row:nth-child(7) { --delay: 7; --offset: 150px }
.animation-row:nth-child(8) { --delay: 8; --offset: -100px }

@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes load-row {
  100% {
    translate: 0;
  }
}

.container.slide:not(.duplicate) > .animation-row {
  animation: slide 1s calc((var(--delay) - 1) * 100ms) both;
}

.animation-row:nth-child(2n) {
  --direction: -1
}

@keyframes slide {
  0% {
    translate: 0;
  }

  100% {
    translate: calc(var(--container-width) * var(--direction, 1));
  }
}

.dark {
  background-color: rgba(50, 50, 50, 1) !important;
  color: white;
}

.dark-section {
  background-color: rgb(33,37,41);
  color: white;
}

body {
  background-color: #f8f8f8 !important;
}

.container {
  margin-top: 10px;
}

section {
  background-color: white;
  border-radius: 15px;
  margin-bottom: 20px;
}

section h2 {
  padding-top: 20px;
  text-align: center;
}

.home-page {
  display: flex;
  flex-direction: column;
  height: 80vh;
  opacity: 0;
  animation: appear 3s 100ms forwards;
}

.home-page-dark {
  display: flex;
  flex-direction: column;
  height: 80vh;
  opacity: 0;
  animation: appear 3s 100ms forwards;
  background-color: black;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.row.menu {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 80px;
}

.menu {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 20px;
  margin-bottom: 20px;
}

.menu-item {
  font-size: 1.5rem;
  text-align: center;
  padding: 15px;
  border-top: 5px solid transparent;
}

.menu-item a {
  color: #a9a9a9 !important;
}

.menu-item a:hover {
  color: #909090;
  text-decoration: none;
  cursor: pointer;
}

.menu-active {
  border-top: 5px solid orange;
  transition: border-top 1s ease;
}

.menu-active a {
  color: #909090 !important;
}

.menu-item button {
  color: #a9a9a9 !important;
  border: none;
  background: transparent;
}

.menu-active button {
  color: #909090 !important;
}

.button-with-icon {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.button-with-icon svg {
  flex: 0 1 2rem;
}

.button-with-icon span {
  min-width: 100px;
}

.carousel {
  max-width: 960px;
  margin: auto;
}

.carousel-item-content {
  display: flex;
}

.carousel-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 40%;
}

.carousel-text-container {
  display: flex;
  width: 50%;
  float: right;
  height: 420px;
  flex-direction: column;
  justify-content: center;
  margin-right: 30px;
}

#hobbies .carousel-text-container {
  flex-grow: 1;
  padding: 0 30px;
}

.carousel-text-container h3 {
  font-size: 2.5rem;
}

.carousel-text-container p {
  padding: 15px 0;
}

.carousel-text-container button {
  margin-bottom: 5px;
}

.carousel-text-container button a {
  color: #fff !important;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}

.about-intro,
.about-intro-dark {
  display: flex;
}

.about-intro-dark {
  background-color: rgb(33,37,41);
  color: white;
}

.about-intro-text {
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  padding: 20px 40px;
}

.journey-stop {
  display: flex;
}

#hobbies .carousel img {
  width: 50%;
  border-radius: 20px;
  /* margin: 50px; */
}

#hobbies:not(.dark-section) .carousel-control-next,
#hobbies:not(.dark-section) .carousel-control-prev,
#hobbies:not(.dark-section) .carousel-indicators {
    filter: invert(100%);
}

#hobbies .carousel-control-next,
#hobbies .carousel-control-prev {
  width: 5%;
}

#hobbies .carousel-inner {
  width: 90%;
  margin: auto;
}

.client-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr)
}

.skills-grid {
  display: grid; 
  grid-template-columns: repeat(2, 1fr);
  gap: 2em; 
  margin: 40px;
}

.image-gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 100px;
}

.image-gallery .gallery-item {
  cursor: pointer;
  position: relative;
}

.image-gallery img {
  width: 100%; 
  aspect-ratio: 1/1;
  object-fit: contain;
  transition: scale 350ms ease, opacity 350ms linear;
}

.image-gallery .rank {
  font-size: 2rem;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 2px 2px #ff0000;
}

.image-gallery:has(.gallery-item:hover) img:not(:hover) {
  scale: 0.8;
  opacity: 0.7;
  object-fit: scale-down;
}

.dark-logo {
  background-color: rgba(255,255,255,0.5);
}

.light-logo {
  background-color: rgba(0,0,0,0.1);
}

.company-logo {
  width: 100%; 
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 0 2px 2px #f8f8f8;
}

/*** 404 Page ***/

.gears:first-child {
  margin-right: 3%;
}

.gears-container {
  width:150px; 
  height: 150px;
  font-size:24px;
  padding: 9%;
  position: relative; 
  margin: 0px auto;
}

.gear-rotate {
  width: 2em;
  height: 2em;
  top: 50%; 
  left: 35%; 
  margin-top: -1em;
  margin-left: -1em;
  background: orange;
  position: absolute;
  border-radius: 1em;
  -webkit-animation: 1s gear-rotate linear infinite;
  -moz-animation: 1s gear-rotate linear infinite;
  animation: 1s gear-rotate linear infinite;
}

.gear-rotate-left {
  margin-top: -2.2em;
  top: 50%;
  left: 55%;
  width: 2em;
  height: 2em;
  background: orange;
  position: absolute;
  border-radius: 1em;
  -webkit-animation: 1s gear-rotate-left linear infinite;
  -moz-animation: 1s gear-rotate-left linear infinite;
  animation: 1s gear-rotate-left linear infinite;
}

.gear-rotate::before, .gear-rotate-left::before {
  width: 2.8em;
  height: 2.8em;
  background: 
    -webkit-linear-gradient(0deg,transparent 39%,orange 39%,orange 61%, transparent 61%),
    -webkit-linear-gradient(60deg,transparent 42%,orange 42%,orange 58%, transparent 58%),
    -webkit-linear-gradient(120deg,transparent 42%,orange 42%,orange 58%, transparent 58%);
  background: 
    -moz-linear-gradient(0deg,transparent 39%,orange 39%,#47EC19 61%, transparent 61%),
    -moz-linear-gradient(60deg,transparent 42%,orange 42%,orange 58%, transparent 58%),
    -moz-linear-gradient(120deg,transparent 42%,orange 42%,orange 58%, transparent 58%);
  background: 
    -o-linear-gradient(0deg,transparent 39%,orange 39%,orange 61%, transparent 61%),
    -o-linear-gradient(60deg,transparent 42%,orange 42%,orange 58%, transparent 58%),
    -o-linear-gradient(120deg,transparent 42%,#47EC19 42%,orange 58%, transparent 58%);
  background: -ms-linear-gradient(0deg,transparent 39%,orange 39%,orange 61%, transparent 61%),-ms-linear-gradient(60deg,transparent 42%,orange 42%,orange 58%, transparent 58%),-ms-linear-gradient(120deg,transparent 42%,orange 42%,orange 58%, transparent 58%);
  background: 
    linear-gradient(0deg,transparent 39%,orange 39%,orange 61%, transparent 61%),
    linear-gradient(60deg,transparent 42%,orange 42%,orange 58%, transparent 58%),
    linear-gradient(120deg,transparent 42%,orange 42%,orange 58%, transparent 58%);
  position: absolute;
  content:"";
  top: -.4em;
  left: -.4em;
  border-radius:1.4em;
}

.gear-rotate::after, .gear-rotate-left::after {
  width: 1em;
  height: 1em;
  background:#f8f8f8;
  position: absolute;
  content:"";
  top: .5em;
  left: .5em;
  border-radius: .5em;
}

.interest-container {
  display: flex; 
  justify-content: space-evenly;
  margin-bottom: 60px;
}

.interest {
  display: flex;
  flex-direction: column;
  text-align: center; 
  padding: 20px;
  text-decoration: none;
}

.interest button {
  width: 100%;
}

.image-grid {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  min-height: 320px;
  max-height: 320px;
}

.interest-img-container {
  display: flex;
  justify-content: center;
}

.image-grid img {
  width: 60px;
  object-fit: contain;
}

/*
* Keyframe Animations 
*/ 

@-webkit-keyframes gear-rotate {
0% { 
  -webkit-transform: rotate(0deg);
}
100% { 
  -webkit-transform: rotate(-180deg); 
}
}

@-moz-keyframes gear-rotate {
0% { 
  transform: rotate(0deg);
}
100% { 
  transform: rotate(-180deg); 
}
}

@keyframes gear-rotate {
0% { 
  transform: rotate(0deg); 
}
100% { 
  transform: rotate(-180deg); 
}
}

@-webkit-keyframes gear-rotate-left {
0% {
 -webkit-transform: rotate(30deg); 
}
100% {
  -webkit-transform: rotate(210deg);
}
}

@-moz-keyframes gear-rotate-left {
0% { 
 -webkit-transform: rotate(30deg); 
}
100% { 
  -webkit-transform: rotate(210deg);
}
}

@keyframes gear-rotate-left {
0% { 
 -webkit-transform: rotate(30deg); 
}
100% { 
  -webkit-transform: rotate(210deg);
}
}

@media(max-width:991px) {

  .animation-container {
    scale: 0.9;
    width: unset;
    height: 70vh;
  }

  .menu-item {
    font-size: 15px;
  }

  .home-page .carousel img,
  #hobbies .carousel img {
    width: 80%;
  }

  .home-page .carousel-image-container {
    flex-basis: 80%;
  }

  .carousel-image {
    display: flex;
    align-items: center;
  }

  .carousel-text-container {
    width: 100%;
    height: 380px;
    align-items: flex-start;
    padding: 0 20px;
    margin: auto;
  }

  .carousel-text-container p {
    padding: 0;
  }

  .client-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .interest-container {
    flex-direction: column;
  }

  .image-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    min-height: 320px;
    max-height: 320px;
  }

  .interest button {
    width: 80%;
  }

  .image-grid img {
    width: 80px;
    object-fit: contain;
  }
}

@media(max-width:767px) {

  .animation-container {
    gap: 4px;
  }

  .animation-row {
    gap: 5px;
  }

  .animation-row {
    height: 15px;
  }

  .carousel img {
      display: none;
  }

  .carousel-item {
    text-align: center;
  }

  .menu-active {
    border-top: none;
    border-left: 10px solid orange;
    border-right: 10px solid orange;
    transition: border-right 1s ease, border-left 1s ease;
  }

  .menu-active.rounded-start {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .menu-active.rounded-end {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .home-page .carousel-image-container,
  .carousel-image-container {
    flex-basis: 0;
    justify-content: center;
  }

  .carousel-text-container {
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 350px;
  }

  .carousel-text-container h4 {
    margin: 0;
  }

  #hobbies .carousel-text-container {
    justify-content: space-evenly;
    margin: auto;
  }

  #hobbies .carousel-text-container p {
    margin: 0;
    padding: 15px;
    flex-grow: 1;
    font-size: 15px;
  }

  #hobbies .carousel-text-container a {
    flex-grow: 1;
  }

  .about-intro,
  .about-intro-dark {
    flex-direction: column;
  }

  .journey-stop {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .skills-grid {
    display: grid; 
    grid-template-columns: 1fr;
    gap: 2em; 
    margin: 40px;
  }

  .image-gallery {
    grid-template-columns: repeat(3, 1fr);
  }

  .button-group {
    display: none !important;
  }

}

@media(max-width:575px) {

  .animation-container {
    gap: 4px;
  }

  .animation-row {
    gap: 5px;
  }

  .menu-item {
    font-size: 18px;
  }

  .client-grid {
    grid-template-columns: 1fr;
  }

  .image-gallery {
    grid-template-columns: repeat(2, 1fr);
  }

  #hobbies .carousel-text-container {
    padding: 0 5px;
  }

  #hobbies .carousel-text-container p {
    font-size: 14px;
    padding: 5px;
  }

  #hobbies .carousel-indicators {
    margin-bottom: 5px;
  }

  .image-grid {
    grid-template-columns: 1fr 1fr 1fr;
    min-height: 400px;
    max-height: 4000px;
  }

}

.employers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 2rem;
  padding-bottom: 30px;
  width: 100%;
}

.employers-grid > div {
  flex-direction: column;
}

section > h2 {
  position: relative;
}

.button-group {
  position: absolute;
  top: 15px;
  right: 15px;
  border: 1px solid;
  border-radius: 5px;
  width: 100px;
  display: flex;
  justify-content: space-evenly;
  align-content: center;
}

.button-group .active {
  background-color: var(--dark);
  color: white;
}

.display-toggle-btn {
  text-align: center;
  width: 50px;
  height: 44px;
  background: none;
  border: none;
  padding: 0px;
}

.display-toggle-btn:nth-child(odd) {
  border-radius: 4px 0 0 4px;
}

.display-toggle-btn:nth-child(even) {
  border-radius: 0 4px 4px 0;
}

.icon-rows {
  height: 50px;
}

.icon-columns {
  width: 60px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}